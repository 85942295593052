<template>
  <div
    class="w-100 h100 registerBody  gutter-space d-flex flex-column justify-center align-center"
  >
    <v-card class="w-100 d-flex flex-row elevation-0 h-100 roundedclass">
      <div class="left-side">
        <img class="left-img" src="../assets/registrationPic.svg" />
      </div>
      <v-divider vertical></v-divider>
      <div class="right-side pt-6">
        <img class="eazilylogo" src="../assets/eazilylogo.svg" />

        <h4 class="text-h5  text-center mt-0 font-weight-medium">
          Pre-Registration Form
        </h4>

        <div class="flex-basis-1 w-100 pt-4 overflow-scroll px-6">
          <div>
            <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
              Name*
            </p>
            <input
              type="text"
              class="text-field"
              v-model="form.name"
              @blur="validateName"
              :class="{ 'is-invalid': !isNameValid }"
              required
            />
            <span v-if="!isNameValid" class="text-red-500"
              >Name is required.</span
            >
          </div>
          <div class="mt-5">
            <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
              Email Address*
            </p>
            <input
              type="email"
              class="text-field"
              v-model="form.email"
              @blur="validateEmail"
              :class="{ 'is-invalid': !isEmailValid }"
              required
            />
            <span v-if="!isEmailValid" class="text-red-500"
              >Valid email is required.</span
            >
          </div>
          <div class="mt-5">
            <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
              Phone Number*
            </p>
            <input
              type="tel"
              class="text-field"
              v-model="form.phone"
              @input="limitPhoneNumber"
              @blur="validatePhone"
              :class="{ 'is-invalid': !isPhoneValid }"
              pattern="[0-9]{10}"
              required
            />
            <span v-if="!isPhoneValid" class="text-red-500"
              >Phone number must be 10 digits.</span
            >
          </div>

          <div class="mt-5">
  <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
    Gender*
  </p>
  <select
  @change="validateGender"
    class="text-field"
    v-model="form.gender"
    :class="{ 'is-invalid': !isGenderValid }"
    required
  >
    <option disabled value="">Select Gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
    <option value="Other">Other</option>
  </select>
  <span v-if="!isGenderValid" class="text-red-500">Gender is required.</span>
</div>











          <div class="mt-5">
            <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
              Objective*
            </p>
            <select
              class="text-field"
              v-model="form.objective"
              @change="onObjectiveChange"
              :class="{ 'is-invalid': !isObjectiveValid }"
              required
            >
              <option disabled value="">Select an objective</option>
              <option
                v-for="(objective, i) in objectives"
                :key="i"
                :value="objective.name"
              >
                {{ objective.name }}
              </option>
            </select>
            <span v-if="!isObjectiveValid" class="text-red-500"
              >Objective is required.</span
            >
          </div>

          <div v-if="showGradesField" class="mt-5">
            <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
              Grade*
            </p>
            <select
              class="text-field"
              v-model="form.selectedGrade"
              :class="{ 'is-invalid': !isGradeValid }"
              required
            >
              <option disabled value="">Select a grade</option>
              <option
                v-for="(grade, i) in grades"
                :key="i"
                :value="grade"
              >
                {{ grade.gradeStream }}
              </option>
            </select>
            <span v-if="!isGradeValid" class="text-red-500">
              Grade is required.
            </span>
          </div>

          <div v-if="showExamsField" class="mt-5">
            <p class="mb-1 text-subtitle-2 font-weight-regular text--secondary">
              Exam*
            </p>
            <select
            @change="validateExam"
              class="text-field"
              v-model="form.exam"
              :class="{ 'is-invalid': !isExamValid }"
              required
            >
              <option disabled value="">Select an exam</option>
              <option
                v-for="(exam, i) in relatedExams"
                :key="i"
                :value="exam.name"
              >
                {{ exam.name }}
              </option>
            </select>
            <span v-if="!isExamValid" class="text-red-500"
              >Exam is required.</span
            >
          </div>
        </div>

        <div class="px-6 w-100">
          <v-btn
            width="100%"
            height="60px"
            color="#F49240"
            class="white--text my-4 elevation-0 w-100"
            @click="submitForm"
            :disabled="isLoading"
          >
            <span v-if="!isLoading">SUBMIT</span>
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="white"
              class="mr-3"
            ></v-progress-circular>
            <v-icon v-if="!isLoading" color="white" class="ml-2"
              >mdi-arrow-right</v-icon
            >
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>
 
 
 <script>
//  import ObjectiveController from "@/controllers/ObjectiveController";
import controllers from "@/controllers/controllers";
export default {
  name: "HomeView",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        objective: "",
        exam: "",
        grade: "",
        gender: "",
        selectedGrade: null,
      },
      objectives: [],
      grades: [],
      selectedObjective: "",

      relatedExams: [],
      isNameValid: true,
      isEmailValid: true,
      isPhoneValid: true,
      isGenderValid:true,
      isObjectiveValid: true,
      isGradeValid: true,
      isExamValid: true,
      isLoading: false,
      showGradesField: false,
      showExamsField: false,
      pathway: '',
      stream: ""
    };
  },
  methods: {
    validateName() {
      this.isNameValid = this.form.name.trim() !== "";
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailPattern.test(this.form.email);
    },
    validatePhone() {
      const phonePattern = /^[0-9]{10}$/;
      this.isPhoneValid = phonePattern.test(this.form.phone);
    },
    validateGender() {
      this.isGenderValid = this.form.gender !== "";
    },


    validateObjective() {
      this.isObjectiveValid = this.form.objective !== "";
    },
    validateExam() {
      this.isExamValid = this.form.exam !== "";
    },
    validateGrade() {
      this.isExamValid = this.form.exam !== "";
    },
    async getGrades() {
      try {
        const response = await controllers.getAllGrades();

        // Check if response and necessary properties exist
        if (
          response &&
          response.status === 200 &&
          response.data &&
          Array.isArray(response.data.grades)
        ) {
          if (response.data.grades.length > 0) {
            this.grades = response.data.grades;
          //  this.stream = response.data.grades.stream;
            this.grades.forEach(
              (obj) =>
                (obj.gradeStream =
                  obj.stream !== "NA" && obj.stream
                    ? obj.name + " - " + obj.stream + ""
                    : obj.name)
            );
            // Grades are successfully fetched and processed
            // console.log("Grades fetched:", this.grades.stream);
            // console.log("Grades fetched12:", this.stream);

          }
        } else {
          alert("No grades found or error in response data.");
        }
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    },
    limitPhoneNumber(event) {
      // Get the input element
      const input = event.target;

      // Limit the value to 10 digits
      if (input.value.length > 10) {
        input.value = input.value.slice(0, 10);
        this.form.phone = input.value;
      }
    },

    // Method to filter exams based on the selected objective
    filterExamsForSelectedObjective() {
      // console.log("chk23");
      const selectedObjective = this.objectives.find(
        (obj) => obj.name === this.form.objective
      );
      // console.log("chk24", typeof selectedObjective.exams);

      if (selectedObjective && selectedObjective.exams) {
        // console.log("chk25");
        this.relatedExams = selectedObjective.exams;
        console.log("chk26", this.relatedExams);
      } else {
        // console.log("chk27");
        this.relatedExams = [];
      }
      this.showGradesField =
        selectedObjective && selectedObjective.pathway === "School Grades";
        this.showExamsField =
        selectedObjective && selectedObjective.pathway === "Exam Preparation";
      this.validateObjective();
    },

    // Method to handle change in selected objective
    onObjectiveChange() {
      this.form.exam = ""; // Reset selected exam
      this.form.grade = "";
      this.filterExamsForSelectedObjective(); // Filter exams for the selected objective
      this.pathway = this.objectives?.filter((obj)=>obj?.name == this.form?.objective)[0]?.pathway;


    },

    async submitForm() {
      try {
        this.validateFormFields();

        if (this.isFormValid()) {
          const payload = this.createPayload();
          this.isLoading = true;

          try {
            const response = await controllers.submitdata(payload);

            if (response?.status === 200) {
              this.$router.push({ path: "/success" }); // Adjust path as needed
            } else if (response?.status === 409) {
              this.$router.push({ path: "/already" });
            } else {
              throw new Error(
                `Unexpected response status: ${response?.status}`
              );
            }

            // Clear the form fields after successful submission
            this.clearFormFields();
          } catch (apiError) {
            console.error("API request failed:", apiError);
            alert("An error occurred while submitting the form.");
          }
        } else {
          alert("Please fill in all required fields correctly.");
        }
      } catch (validationError) {
        console.error("Validation error:", validationError);
        alert("Please check your form fields.");
      } finally {
        this.isLoading = false;
      }
    },
    validateFormFields() {
      this.validateName();
      this.validateEmail();
      this.validatePhone();
      this.validateObjective();
      this.validateGender();
      // this.validateExam();
    },

    isFormValid() {
      return (
        this.isNameValid &&
        this.isEmailValid &&
        this.isPhoneValid &&
        this.isObjectiveValid &&
        this.isGenderValid &&
        (this.isExamValid || this.isGradeValid)
      );
    },

    clearFormFields() {
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.objective = "";
      this.form.exam = "";
      // Add more fields as needed
    },
    createPayload() {
      const selectedGrade = this.form.selectedGrade || {};
      



      return {
        name: this.form.name || "NA",
        phoneNumber: this.form.phone || "NA",
        email: this.form.email || "NA",
        objective: this.form.objective || "NA",
        grade: selectedGrade.name || "NA",
        stream: selectedGrade.stream || "NA",
        // grade: this.form.grade || "NA",
        exam: this.form.exam || "NA",
        pathway: this.pathway || 'NA',
        gender: this.form.gender || 'NA'
        

      };
    },
    sortByAlphabet(array, key) {
      return array.sort((a, b) => {
        const nameA = a[key].toUpperCase(); // Ignore case
        const nameB = b[key].toUpperCase(); // Ignore case

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // Names must be equal
        return 0;
      });
    },
    async getObjectives() {
      const response = await controllers.getobjectives();
      console.log("chk1:", response);
      if (response.status == 200) {
        if (response.data.objectives.length > 0) {
          const objs = response.data.objectives;
          this.objectives = this.sortByAlphabet(objs, "name");
        }
      } else {
        alert("Something went wrong");
      }
    },
  },
  mounted() {
    this.getObjectives(); // Load objectives when component is mounted
    this.getGrades();
  },
};
</script>
 
 
 <style>
.text-field {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.is-invalid {
  border: 1px solid red;
}

.text-red-500 {
  color: red;
  font-size: 12px;
}
</style>