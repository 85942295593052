<template>
   <div class="w-100 h100 registerBody px-6 py-4 d-flex flex-column justify-center align-center">
      <v-card class="w-100 px-6 d-flex flex-column justify-center align-center elevation-0 h-100">

         <img class="eazilyRightlogo " src="../assets/eazilylogo.svg">

         <!-- c:\Users\learn\Downloads\complete.svg -->
         <img width="60" src="../assets/complete.svg">
         <h4 class="text-xl-h4 mt-4 text-lg-h4 text-md-h4 text-h6 text-sm-h4 font-weight-bold text-center">Thank you</h4>
         <div class="text-center mt-2" style="max-width: 400px;">Your email address is already registered.</div>



      </v-card>
   </div>
</template>


<script>
export default {
   name: 'HomeView',
   data() {
      return {
         form: {
            name: '',
            email: '',
            phone: '',
            objective: '',
            exam: '',
         },
         objectives: ['Competitive', 'School', 'College'],
         exams: ['IIT JEE', 'NEET', 'UPSC'],
         isNameValid: true,
         isEmailValid: true,
         isPhoneValid: true,
         isObjectiveValid: true,
         isExamValid: true,
      };
   },
   methods: {
      validateName() {
         this.isNameValid = this.form.name.trim() !== '';
      },
      validateEmail() {
         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         this.isEmailValid = emailPattern.test(this.form.email);
      },
      validatePhone() {
         const phonePattern = /^[0-9]{10}$/;
         this.isPhoneValid = phonePattern.test(this.form.phone);
      },
      validateObjective() {
         this.isObjectiveValid = this.form.objective !== '';
      },
      validateExam() {
         this.isExamValid = this.form.exam !== '';
      },
      submitForm() {
         this.validateName();
         this.validateEmail();
         this.validatePhone();
         this.validateObjective();
         this.validateExam();

         if (this.isNameValid && this.isEmailValid && this.isPhoneValid && this.isObjectiveValid && this.isExamValid) {
            // Submit form
            alert('Form submitted successfully!');
         } else {
            alert('Please fill in all required fields correctly.');
         }
      }
   }
};
</script>

<style>
.text-field {
   width: 100%;
   padding: 8px;
   box-sizing: border-box;
}

.is-invalid {
   border: 1px solid red;
}

.text-red-500 {
   color: red;
   font-size: 12px;
}
</style>
