import axios from "axios";

const campaingInstance = axios.create({
  baseURL: "https://ms-campaign-yyhijobgga-em.a.run.app/",
});
// const campaingInstance = axios.create({
//   baseURL: 'http://localhost:3000/'
// });

export default {
  getHomeData: async function () {
    try {
      const response = await campaingInstance.get(`get-total-users-count`);
      return response;
    } catch (error) {
      return error.response;
    }
  },

  submitdata: async function (payload) {
    try {
      const response = await campaingInstance.post(
        `save-campaign-data`,
        payload
      ); // Pass the payload in the POST request
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getobjectives: async function () {
    try {
      const response = await campaingInstance.get(`objectives`, {});
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllGrades: async function () {
    try {
      const response = await campaingInstance.get(`grades`);
      return response;
    } catch (error) {
      return error.response;
    }
  },



};
