import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import SuccessView from '@/views/SuccessView.vue'
import AlreadySubmitted from '@/views/AlreadySubmitted.vue'






Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
  ,
  {
    path: '/register',
    name: 'Register',
    component: RegistrationView
  },
  {
    path: '/success',
    name: 'Sucess',
    component: SuccessView
  },
  {
    path: '/already',
    name: 'already',
    component: AlreadySubmitted
  }

]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes
})

export default router
