<template>
  <v-app>

      <router-view/>

  </v-app>
</template>

<script>
import './style.css';
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
