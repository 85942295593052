<template>
  <div class="w-100 h100 homeBody d-flex flex-column justify-center align-center">
    <img class="eazilyhomelogo "  src="../assets/eazilylogo.svg">

    <img class="left-flag" src="../assets/left flag.svg">
    <img class="right-flag" src="../assets/rightflag.png">
    <div class="d-flex flex-column mt-n9 justify-center align-center">
      <h1>Join Eazily Today!</h1>
      <h5 style="text-align: center; " class="text-h6 font-weight-regular">Be the first to experience our revolutionary learning platform.</h5>
      <div class="d-flex flex-row mt-8">
        <span>
          <div class="d-flex px-4 flex-column">
            <h2 class="text-center text-xl-h2 text-lg-h2 text-md-h2 text-xs-h3 text-sm-h3 w-600">{{ formatTime(days) }}
            </h2>
            <caption class="w-300  text-sm-subtitle-1 text-lg-h5 text-xl-h5 text-md-h5 ">DAYS</caption>
          </div>
        </span>
        <v-divider vertical></v-divider>
        <span>
          <div class="d-flex px-4 flex-column">
            <h2 class="text-center text-xl-h2 text-lg-h2 text-md-h2 text-xs-h3 text-sm-h3 w-600">{{ formatTime(hours) }}
            </h2>
            <caption class="w-300 text-sm-subtitle-1 text-lg-h5 text-xl-h5 text-md-h5 ">HOURS</caption>
          </div>
        </span>
        <v-divider vertical></v-divider>
        <span>
          <div class="d-flex px-4 flex-column">
            <h2 class="text-center text-xl-h2 text-lg-h2 text-md-h2 text-xs-h3 text-sm-h3 w-600">{{ formatTime(minutes)
              }}</h2>
            <caption class="w-300  text-sm-subtitle-1 text-lg-h5 text-xl-h5 text-md-h5 ">MINUTES</caption>
          </div>
        </span>
        <v-divider vertical></v-divider>
        <span>
          <div class="d-flex px-4 flex-column">
            <h2 class="text-center text-xl-h2 text-lg-h2 text-md-h2 text-xs-h3 text-sm-h3 w-600">{{ formatTime(seconds)
              }}</h2>
            <caption class="w-300  text-sm-subtitle-1 text-lg-h5 text-xl-h5 text-md-h5 ">SECONDS</caption>
          </div>
        </span>
      </div>
      <div style="width: fit-content;" class="d-flex flex-column justify-center align-center">
        <v-btn to="/register" large class="white--text w-100 black mt-6 px-8 rounded-36">Pre-Register Now</v-btn>
        <caption class="mt-4 caption w-600">{{ calculatePercentage(occupiedCount, totalSeat) }}%</caption>
        <div style="position: relative;height: 10px;" class="relative w-100">
          <v-progress-linear  v-model="percentage" height="10" style="position: absolute;top: 0px;z-index: 0"
            class=" rounded-lg custom-progress"></v-progress-linear>
          <div class="w-100 " style="z-index: 10; position: absolute; top: 0px; height: 10px;"></div>
        </div>
        <h6 class="text-h6 font-weight-regular mx-4">Seats Left: {{ totalSeat - occupiedCount }} out of {{ totalSeat }}
        </h6>
      </div>
    </div>



    <div class="d-flex bottom-flag flex-row justify-space-between w-100">
      <img class="sale " src="../assets/discount.svg">
      <img class="freedomflag" src="../assets/sale.svg">   
      <span class="terms m-caption font-weight-regular mx-2">*Terms and conditions apply. Offer valid for the first 15,000 registrations only.</span>
 
    </div>

  </div>
</template>

<script>
import campaignController from "../controllers/controllers";
import { db } from "../firebase";
import {

  onSnapshot,
  doc,

} from "firebase/firestore";
export default {
  name: 'HomeView',
  data() {
    return {
      days: 0,
      hours: 0,
      occupiedCount: 0,
      totalSeat: 0,
      minutes: 0,
      gender: '',
      seconds: 0,
      interval: null,
      percentage: 0,

    };
  },
  mounted() {
    this.startCountdown();
    this.loadEnrolledUserCount()
    // this.getHomeData();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async getHomeData() {
      const response = await campaignController.getHomeData()
      if (response.status == 200) {
        this.occupiedCount = response?.data?.totalRegistered || 0
        this.totalSeat = response?.data?.totalCount || 0


      }
    },

    loadEnrolledUserCount() {
      const snapshotRef = doc(
        db,
        "campaigns",
        "15AUG"
      );

      onSnapshot(
        snapshotRef,
        (snapshot) => {
          if (snapshot.exists()) {
            this.occupiedCount = snapshot.data()?.totalRegistered || 0
            this.totalSeat = snapshot.data()?.totalCount || 0

            this.percentage = (this.occupiedCount/this.totalSeat)*100
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error fetching document:", error);
        }
      );
    },


    calculatePercentage(part, total) {
      // Handle potential division by zero
      if (total === 0) {
        return 0;
      }

      const percentage = (part / total) * 100;
      return percentage.toFixed(2); // Limit to two decimal places
    },
    handleClick() {
      console.log("Progress bar clicked!");
      // Add any additional logic you want to execute on click
    },
    startCountdown() {
      const endDate = new Date('September 5, 2024 23:59:59').getTime();
      this.interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = endDate - now;

        if (distance < 0) {
          clearInterval(this.interval);
          this.days = this.hours = this.minutes = this.seconds = 0;
          return;
        }

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
    formatTime(time) {
      return time < 10 ? '0' + time : time;
    },
  },
};
</script>
