// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: 'AIzaSyDLAXjDYjW6dEZHkv4oi3SQ8zuFoCjJDic',
  databaseURL: 'https://eazily-lms-staging-default-rtdb.firebaseio.com',
  projectId: 'eazily-lms-staging',
  appId: '1:819079937788:web:88ee85146c8b277331a179',
};




const app = initializeApp(firebaseConfig, 'destination');

const db=getFirestore(app);



export {db,app};


